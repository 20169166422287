<template>
  <section id="content" ref="modulesContent">
    <PageTitle :title="$t('vat-validation.admin.title')"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div class="row generate-success">
        <div class="col-lg-12">
          <ListModules :genericModuleInput="genericModuleInput">
            <template v-slot:adminSettingsForm="scope">
              <AdminSettingsForm :module="scope.module" v-on:module-updated="scope.onModuleUpdated" />
            </template>
          </ListModules>
        </div>
      </div>

      <div class="row generate-success">
        <div class="col-lg-12">
          <CopyModule :apiModulePath="apiModulePath"></CopyModule>
        </div>
      </div>

    </div>
  </section>
</template>

<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { useGenericPage } from '@fwk-client/composables/useGenericPage';
import { apiModulePath, genericModuleInput } from '../../../composables/useModuleAdmin';

import PageTitle from '@root/src/client/components/panels/PageTitle.vue';

import ListModules from '../../../../generic/components/panels/admin/modules/ListModules.vue';
import CopyModule from '../../../../generic/components/panels/admin/modules/Copy.vue';
import AdminSettingsForm from '../../panels/admin/modules/AdminSettingsForm.vue';

export default defineComponent({
  props: {
  },
  components: {
    PageTitle,
    ListModules,
    CopyModule,
    AdminSettingsForm
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const genericPage = useGenericPage(props, context);

    return {
      apiModulePath: apiModulePath,
      genericModuleInput: genericModuleInput
    }
  }
});
</script>